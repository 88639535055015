const mobInfo = [
  {
    "Unnamed: 0": 1,
    이름: "달팽이",
    레벨: 1.0,
    HP: 5.0,
    공격력: 7.0,
    회피율: 0.0,
    경험치: 10.0,
    메소: 1.0,
    주문서: "망힘/투민/전민",
    mobCode: 100100,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "메이플아일랜드",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 2,
  },
  {
    "Unnamed: 0": 2,
    이름: "파란 달팽이",
    레벨: 2.0,
    HP: 10.0,
    공격력: 8.0,
    회피율: 0.0,
    경험치: 20.0,
    메소: 1.0,
    주문서: "망힘",
    mobCode: 9300217,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "메이플아일랜드",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 2,
  },
  {
    "Unnamed: 0": 3,
    이름: "스포아",
    레벨: 2.0,
    HP: 10.0,
    공격력: 8.0,
    회피율: 0.0,
    경험치: 20.0,
    메소: 1.0,
    주문서: "X",
    mobCode: 120100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "메이플아일랜드",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 2,
  },
  {
    "Unnamed: 0": 4,
    이름: "빨간 달팽이",
    레벨: 4.0,
    HP: 25.0,
    공격력: 10.0,
    회피율: 0.0,
    경험치: 40.0,
    메소: 1.0,
    주문서: "망지/전민",
    mobCode: 9300218,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "메이플아일랜드",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.6,
  },
  {
    "Unnamed: 0": 5,
    이름: "스텀프",
    레벨: 4.0,
    HP: 25.0,
    공격력: 10.0,
    회피율: 0.0,
    경험치: 40.0,
    메소: 1.0,
    주문서: "망힘/상힘",
    mobCode: 9300219,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산1",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.6,
  },
  {
    "Unnamed: 0": 6,
    이름: "슬라임",
    레벨: 6.0,
    HP: 45.0,
    공격력: 20.0,
    회피율: 0.0,
    경험치: 60.0,
    메소: 2.0,
    주문서: "하행/전행",
    mobCode: 9500100,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "남쪽숲나무던전1(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.333333333,
  },
  {
    "Unnamed: 0": 7,
    이름: "돼지",
    레벨: 7.0,
    HP: 65.0,
    공격력: 22.0,
    회피율: 0.0,
    경험치: 90.0,
    메소: 2.0,
    주문서: "망행",
    mobCode: 9500101,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "헤네시스돼지농장(히든), 돼지의해안가(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.384615385,
  },
  {
    "Unnamed: 0": 8,
    이름: "주황버섯",
    레벨: 10.0,
    HP: 100.0,
    공격력: 25.0,
    회피율: 0.0,
    경험치: 120.0,
    메소: 3.0,
    주문서: "신민",
    mobCode: 9500102,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "버섯동산(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.2,
  },
  {
    "Unnamed: 0": 9,
    이름: "리본돼지",
    레벨: 10.0,
    HP: 100.0,
    공격력: 25.0,
    회피율: 0.0,
    경험치: 120.0,
    메소: 3.0,
    주문서: "방방",
    mobCode: 9300059,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "헤네시스돼지농장(히든), 돼지의해안가(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.2,
  },
  {
    "Unnamed: 0": 10,
    이름: "다크 스텀프",
    레벨: 10.0,
    HP: 100.0,
    공격력: 25.0,
    회피율: 0.0,
    경험치: 120.0,
    메소: 3.0,
    주문서: "장공",
    mobCode: 9200002,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산2",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.2,
  },
  {
    "Unnamed: 0": 11,
    이름: "옥토퍼스",
    레벨: 12.0,
    HP: 130.0,
    공격력: 35.0,
    회피율: 20.0,
    경험치: 140.0,
    메소: 3.0,
    주문서: "한손검/하행",
    mobCode: 9500104,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "추락주의(히든), 북쪽공사장꼭대기(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 1.076923077,
  },
  {
    "Unnamed: 0": 12,
    이름: "초록버섯",
    레벨: 15.0,
    HP: 160.0,
    공격력: 40.0,
    회피율: 24.0,
    경험치: 150.0,
    메소: 4.0,
    주문서: "망민/한손도끼",
    mobCode: 9500105,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "북쪽숲나무던전6(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.9375,
  },
  {
    "Unnamed: 0": 13,
    이름: "버블링",
    레벨: 15.0,
    HP: 160.0,
    공격력: 40.0,
    회피율: 24.0,
    경험치: 150.0,
    메소: 4.0,
    주문서: "망민/망힘",
    mobCode: 9500103,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "1호선<1구역>",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.9375,
  },
  {
    "Unnamed: 0": 14,
    이름: "엑스텀프",
    레벨: 17.0,
    HP: 190.0,
    공격력: 50.0,
    회피율: 25.0,
    경험치: 160.0,
    메소: 4.0,
    주문서: "한손둔기",
    mobCode: 9300220,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산3",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.8421052632,
  },
  {
    "Unnamed: 0": 15,
    이름: "고스텀프",
    레벨: 19.0,
    HP: 220.0,
    공격력: 60.0,
    회피율: 30.0,
    경험치: 180.0,
    메소: 5.0,
    주문서: "장마/전민",
    mobCode: 1140100,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "유적발굴지1",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.8181818182,
  },
  {
    "Unnamed: 0": 16,
    이름: "파란버섯",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 200.0,
    메소: 5.0,
    주문서: "두손검",
    mobCode: 9300259,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "파란버섯의숲(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 17,
    이름: "스티지",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 200.0,
    메소: 5.0,
    주문서: "망지/투지",
    mobCode: 9300082,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "환승구역",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 18,
    이름: "흰모래 토끼",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 200.0,
    메소: 5.0,
    주문서: null,
    mobCode: 2100100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 19,
    이름: "주니어 네키",
    레벨: 21.0,
    HP: 300.0,
    공격력: 80.0,
    회피율: 33.0,
    경험치: 230.0,
    메소: 5.0,
    주문서: "방방",
    mobCode: 9300000,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "주니어네키의늪(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.7666666667,
  },
  {
    "Unnamed: 0": 20,
    이름: "갈색 모래 토끼",
    레벨: 21.0,
    HP: 300.0,
    공격력: 80.0,
    회피율: 33.0,
    경험치: 230.0,
    메소: 5.0,
    주문서: null,
    mobCode: 2100101,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.7666666667,
  },
  {
    "Unnamed: 0": 21,
    이름: "뿔버섯",
    레벨: 22.0,
    HP: 350.0,
    공격력: 85.0,
    회피율: 35.0,
    경험치: 260.0,
    메소: 6.0,
    주문서: "두손도끼/두손검",
    mobCode: 9500106,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "남쪽숲나무던전4(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.7428571429,
  },
  {
    "Unnamed: 0": 22,
    이름: "다크 엑스텀프",
    레벨: 22.0,
    HP: 350.0,
    공격력: 85.0,
    회피율: 35.0,
    경험치: 260.0,
    메소: 6.0,
    주문서: "두손둔기",
    mobCode: 2130100,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산5",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.7428571429,
  },
  {
    "Unnamed: 0": 23,
    이름: "핀붐",
    레벨: 22.0,
    HP: 350.0,
    공격력: 85.0,
    회피율: 35.0,
    경험치: 260.0,
    메소: 6.0,
    주문서: "두손둔기",
    mobCode: 2230108,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뾰족한사각지대",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.7428571429,
  },
  {
    "Unnamed: 0": 24,
    이름: "주니어 카투스",
    레벨: 22.0,
    HP: 350.0,
    공격력: 85.0,
    회피율: 35.0,
    경험치: 260.0,
    메소: 6.0,
    주문서: null,
    mobCode: 2100102,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.7428571429,
  },
  {
    "Unnamed: 0": 25,
    이름: "우드 마스크",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "망지/방방",
    mobCode: 2230110,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "유적발굴지2",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 26,
    이름: "주니어 스톤볼",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "두손도끼",
    mobCode: 5200000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑18~20층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 27,
    이름: "씨클",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "방방",
    mobCode: 9800017,
    독: 1.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "물고기쉼터(히든)",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 28,
    이름: "벨라모아",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: null,
    mobCode: 2100105,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 29,
    이름: "트릭스터",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "방방",
    mobCode: 2230103,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑73층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 30,
    이름: "좀비버섯",
    레벨: 24.0,
    HP: 470.0,
    공격력: 95.0,
    회피율: 38.0,
    경험치: 330.0,
    메소: 7.0,
    주문서: "창",
    mobCode: 9300238,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "개미굴4",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.7021276596,
  },
  {
    "Unnamed: 0": 31,
    이름: "스톤 마스크",
    레벨: 24.0,
    HP: 470.0,
    공격력: 95.0,
    회피율: 38.0,
    경험치: 330.0,
    메소: 7.0,
    주문서: "귀지/창/스태프",
    mobCode: 2230111,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "유적발굴지3",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.7021276596,
  },
  {
    "Unnamed: 0": 32,
    이름: "크라피",
    레벨: 24.0,
    HP: 470.0,
    공격력: 95.0,
    회피율: 38.0,
    경험치: 330.0,
    메소: 7.0,
    주문서: "망행/창",
    mobCode: 2230107,
    독: 1.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "버섯산호구릉",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.7021276596,
  },
  {
    "Unnamed: 0": 33,
    이름: "귀마개 프릴드",
    레벨: 24.0,
    HP: 470.0,
    공격력: 95.0,
    회피율: 38.0,
    경험치: 330.0,
    메소: 7.0,
    주문서: null,
    mobCode: 2100106,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.7021276596,
  },
  {
    "Unnamed: 0": 34,
    이름: "모래두더지",
    레벨: 24.0,
    HP: 470.0,
    공격력: 95.0,
    회피율: 38.0,
    경험치: 330.0,
    메소: 7.0,
    주문서: null,
    mobCode: 2110300,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.7021276596,
  },
  {
    "Unnamed: 0": 35,
    이름: "와일드보어",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: "방방",
    mobCode: 9200000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "와일드보어의땅1,2(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 36,
    이름: "씨코",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: "투지/상힘",
    mobCode: 9800018,
    독: 1.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "푸른해초길",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 37,
    이름: "카투스",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: null,
    mobCode: 9300221,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 38,
    이름: "이블아이",
    레벨: 27.0,
    HP: 700.0,
    공격력: 105.0,
    회피율: 43.0,
    경험치: 450.0,
    메소: 8.0,
    주문서: "폴암",
    mobCode: 9200011,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "이블아이의굴3",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.6428571429,
  },
  {
    "Unnamed: 0": 39,
    이름: "목도리 프릴드",
    레벨: 27.0,
    HP: 700.0,
    공격력: 105.0,
    회피율: 43.0,
    경험치: 450.0,
    메소: 8.0,
    주문서: null,
    mobCode: 2100107,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.6428571429,
  },
  {
    "Unnamed: 0": 40,
    이름: "버블피쉬",
    레벨: 28.0,
    HP: 800.0,
    공격력: 110.0,
    회피율: 44.0,
    경험치: 500.0,
    메소: 8.0,
    주문서: "장공/신민",
    mobCode: 2230109,
    독: 1.0,
    불: 2.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "빅피쉬골짜기",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.625,
  },
  {
    "Unnamed: 0": 41,
    이름: "로얄카투스",
    레벨: 28.0,
    HP: 800.0,
    공격력: 110.0,
    회피율: 44.0,
    경험치: 500.0,
    메소: 8.0,
    주문서: null,
    mobCode: 9300222,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.625,
  },
  {
    "Unnamed: 0": 42,
    이름: "그린 트릭스터",
    레벨: 28.0,
    HP: 800.0,
    공격력: 110.0,
    회피율: 44.0,
    경험치: 500.0,
    메소: 8.0,
    주문서: "하행",
    mobCode: 2230104,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑71층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.625,
  },
  {
    "Unnamed: 0": 43,
    이름: "플라워피쉬",
    레벨: 29.0,
    HP: 900.0,
    공격력: 115.0,
    회피율: 46.0,
    경험치: 550.0,
    메소: 9.0,
    주문서: "석궁/장공",
    mobCode: 2230200,
    독: 2.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "모래성놀이터",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.6111111111,
  },
  {
    "Unnamed: 0": 44,
    이름: "미요캐츠",
    레벨: 29.0,
    HP: 900.0,
    공격력: 115.0,
    회피율: 46.0,
    경험치: 550.0,
    메소: 9.0,
    주문서: null,
    mobCode: 2100108,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.6111111111,
  },
  {
    "Unnamed: 0": 45,
    이름: "스콜피언",
    레벨: 29.0,
    HP: 900.0,
    공격력: 115.0,
    회피율: 46.0,
    경험치: 550.0,
    메소: 9.0,
    주문서: null,
    mobCode: 9300157,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.6111111111,
  },
  {
    "Unnamed: 0": 46,
    이름: "스톤볼",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "망지/망민",
    mobCode: 3000000,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 1.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑14,15층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 47,
    이름: "파이어 스톤볼",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "장공",
    mobCode: 5200002,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑11층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 48,
    이름: "아이스 스톤볼",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "망지",
    mobCode: 5200001,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑13층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 49,
    이름: "크립",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "하행",
    mobCode: 9800019,
    독: 1.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "바다해초탑",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 50,
    이름: "키요",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: null,
    mobCode: 3100102,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 51,
    이름: "브라운 테니",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "신민",
    mobCode: 3000005,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "테라스홀",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 52,
    이름: "북치는 토끼",
    레벨: 31.0,
    HP: 1210.0,
    공격력: 125.0,
    회피율: 49.0,
    경험치: 700.0,
    메소: 10.0,
    주문서: "장마",
    mobCode: 9500107,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "북치는토끼의은신처(히든)",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5785123967,
  },
  {
    "Unnamed: 0": 53,
    이름: "처프",
    레벨: 31.0,
    HP: 1210.0,
    공격력: 125.0,
    회피율: 49.0,
    경험치: 700.0,
    메소: 10.0,
    주문서: "활/석궁",
    mobCode: 3230307,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑92층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5785123967,
  },
  {
    "Unnamed: 0": 54,
    이름: "파이어보어",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 10.0,
    주문서: "방방",
    mobCode: 9200001,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산6",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 55,
    이름: "리게이터",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: "망힘",
    mobCode: 9500108,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "방황의늪1",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 56,
    이름: "리티",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: "신민",
    mobCode: 5300000,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑6,7층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 57,
    이름: "마스크피쉬",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: "장공/신민",
    mobCode: 9800020,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바다갈림길",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 58,
    이름: "모래난쟁이",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: null,
    mobCode: 9810201,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 59,
    이름: "붉은 모래난쟁이",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: null,
    mobCode: 3110301,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 60,
    이름: "큐브슬라임",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: null,
    mobCode: 3110300,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 61,
    이름: "라츠",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: "폴암",
    mobCode: 9500109,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑1층, 에오스탑100층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 62,
    이름: "핑크 테니",
    레벨: 32.0,
    HP: 1320.0,
    공격력: 130.0,
    회피율: 51.0,
    경험치: 750.0,
    메소: 11.0,
    주문서: "망지",
    mobCode: 3110101,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "구름테라스2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5681818182,
  },
  {
    "Unnamed: 0": 63,
    이름: "주니어 샐리온",
    레벨: 33.0,
    HP: 1430.0,
    공격력: 140.0,
    회피율: 52.0,
    경험치: 790.0,
    메소: 12.0,
    주문서: "아대",
    mobCode: 3210200,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "붉은빛의정원1",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5524475524,
  },
  {
    "Unnamed: 0": 64,
    이름: "주니어 라이오너",
    레벨: 33.0,
    HP: 1430.0,
    공격력: 140.0,
    회피율: 52.0,
    경험치: 790.0,
    메소: 12.0,
    주문서: "활",
    mobCode: 3210201,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "노란빛의정원1",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5524475524,
  },
  {
    "Unnamed: 0": 65,
    이름: "주니어 그류핀",
    레벨: 33.0,
    HP: 1430.0,
    공격력: 140.0,
    회피율: 52.0,
    경험치: 790.0,
    메소: 12.0,
    주문서: "망지/완드",
    mobCode: 3210202,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "푸른빛의정원1",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5524475524,
  },
  {
    "Unnamed: 0": 66,
    이름: "다크 리티",
    레벨: 33.0,
    HP: 1430.0,
    공격력: 140.0,
    회피율: 52.0,
    경험치: 790.0,
    메소: 12.0,
    주문서: "상힘",
    mobCode: 5300001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑3,4층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5524475524,
  },
  {
    "Unnamed: 0": 67,
    이름: "더키패밀리",
    레벨: 34.0,
    HP: 1540.0,
    공격력: 150.0,
    회피율: 54.0,
    경험치: 840.0,
    메소: 13.0,
    주문서: "한손둔기",
    mobCode: 3210204,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "하늘테라스5",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5454545455,
  },
  {
    "Unnamed: 0": 68,
    이름: "티키",
    레벨: 34.0,
    HP: 1540.0,
    공격력: 150.0,
    회피율: 54.0,
    경험치: 840.0,
    메소: 13.0,
    주문서: "한손도끼",
    mobCode: 9300232,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시간의갈림길",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5454545455,
  },
  {
    "Unnamed: 0": 69,
    이름: "블랙 라츠",
    레벨: 34.0,
    HP: 1540.0,
    공격력: 150.0,
    회피율: 54.0,
    경험치: 840.0,
    메소: 13.0,
    주문서: "한손도끼/한손둔기",
    mobCode: 3210205,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑3층, 에오스탑95층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5454545455,
  },
  {
    "Unnamed: 0": 70,
    이름: "커즈아이",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "활",
    mobCode: 9300002,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "북쪽숲나무던전3(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 71,
    이름: "주니어 레이스",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "단검/투민",
    mobCode: 9500157,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "1호선<2구역>, 2호선<1구역>",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 72,
    이름: "스타픽시",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "망힘/망민/방방",
    mobCode: 9500110,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": "구름공원1, 구름공원2",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 73,
    이름: "주니어 페페",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "스태프",
    mobCode: 9500112,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑1층",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 74,
    이름: "루모",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: null,
    mobCode: 3110302,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 75,
    이름: "블록퍼스",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "망행",
    mobCode: 9300128,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑41층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 76,
    이름: "스쿠버 페페",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "귀지",
    mobCode: 3210450,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "오르비스탑지하2층",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 77,
    이름: "팬더 테니",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "활",
    mobCode: 3210203,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "장난감공장<메인공정1>",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 78,
    이름: "치크세이버",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "한손검",
    mobCode: 9500117,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑60층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 79,
    이름: "레츠",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "활",
    mobCode: 9300129,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "헬리오스탑2층, 헬리오스탑100층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 80,
    이름: "루팡",
    레벨: 37.0,
    HP: 2190.0,
    공격력: 180.0,
    회피율: 60.0,
    경험치: 1110.0,
    메소: 16.0,
    주문서: "망지",
    mobCode: 9300225,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "원숭이의숲1(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5068493151,
  },
  {
    "Unnamed: 0": 81,
    이름: "로랑",
    레벨: 37.0,
    HP: 2190.0,
    공격력: 180.0,
    회피율: 60.0,
    경험치: 1110.0,
    메소: 16.0,
    주문서: "단검",
    mobCode: 9500115,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "로랑로랑로랑(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.5068493151,
  },
  {
    "Unnamed: 0": 82,
    이름: "크로노스",
    레벨: 37.0,
    HP: 2190.0,
    공격력: 180.0,
    회피율: 60.0,
    경험치: 1110.0,
    메소: 16.0,
    주문서: "두손둔기/완드",
    mobCode: 9300130,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "시간의길2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5068493151,
  },
  {
    "Unnamed: 0": 83,
    이름: "핑크세이버",
    레벨: 37.0,
    HP: 2190.0,
    공격력: 180.0,
    회피율: 60.0,
    경험치: 1110.0,
    메소: 16.0,
    주문서: "단검",
    mobCode: 3230303,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑58층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5068493151,
  },
  {
    "Unnamed: 0": 84,
    이름: "주니어 씰",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "신민",
    mobCode: 3230405,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "수정협곡",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 85,
    이름: "트리플 루모",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: null,
    mobCode: 3110303,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 86,
    이름: "킹 블록퍼스",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "귀지/신민",
    mobCode: 9500114,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑22층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 87,
    이름: "스카이세이버",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "귀지",
    mobCode: 3230304,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑25층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 88,
    이름: "장난감 목마",
    레벨: 39.0,
    HP: 2730.0,
    공격력: 200.0,
    회피율: 63.0,
    경험치: 1330.0,
    메소: 18.0,
    주문서: "망힘/망민",
    mobCode: 9500119,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "구름테라스5",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4871794872,
  },
  {
    "Unnamed: 0": 89,
    이름: "트위터",
    레벨: 39.0,
    HP: 2730.0,
    공격력: 200.0,
    회피율: 63.0,
    경험치: 1330.0,
    메소: 18.0,
    주문서: "방행",
    mobCode: 9500118,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑6층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4871794872,
  },
  {
    "Unnamed: 0": 90,
    이름: "콜드아이",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "투민",
    mobCode: 9200013,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "또다른길",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 91,
    이름: "좀비루팡",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "창",
    mobCode: 9500116,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "원숭이의늪3(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 92,
    이름: "푸퍼",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "투지/신민",
    mobCode: 4230200,
    독: 1.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "바다나들목",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 93,
    이름: "포이즌 푸퍼",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "장마/방방",
    mobCode: 4230201,
    독: 2.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "수정협곡",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 94,
    이름: "별다람쥐",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: null,
    mobCode: 4230500,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 95,
    이름: "모래거인",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: null,
    mobCode: 9810202,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 96,
    이름: "틱톡",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "아대/상힘",
    mobCode: 9300233,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시간의소용돌이",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 97,
    이름: "바나드 그레이",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "망힘/신민",
    mobCode: 9500366,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "쿨란초원1",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 98,
    이름: "플라이아이",
    레벨: 41.0,
    HP: 3400.0,
    공격력: 240.0,
    회피율: 66.0,
    경험치: 1600.0,
    메소: 21.0,
    주문서: "망힘/한손둔기",
    mobCode: 9300081,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "통로",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.4705882353,
  },
  {
    "Unnamed: 0": 99,
    이름: "호저",
    레벨: 41.0,
    HP: 3400.0,
    공격력: 240.0,
    회피율: 66.0,
    경험치: 1600.0,
    메소: 21.0,
    주문서: null,
    mobCode: 4230501,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.4705882353,
  },
  {
    "Unnamed: 0": 100,
    이름: "마티안",
    레벨: 41.0,
    HP: 3400.0,
    공격력: 240.0,
    회피율: 66.0,
    경험치: 1600.0,
    메소: 21.0,
    주문서: "장공",
    mobCode: 9500371,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "통제구역",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.4705882353,
  },
  {
    "Unnamed: 0": 101,
    이름: "로보 토이",
    레벨: 41.0,
    HP: 3400.0,
    공격력: 240.0,
    회피율: 66.0,
    경험치: 1600.0,
    메소: 21.0,
    주문서: "하행/단검",
    mobCode: 4230111,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "장난감공장<2공정>5구역",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4705882353,
  },
  {
    "Unnamed: 0": 102,
    이름: "플래툰 크로노스",
    레벨: 41.0,
    HP: 3400.0,
    공격력: 240.0,
    회피율: 66.0,
    경험치: 1600.0,
    메소: 21.0,
    주문서: "장마/완드",
    mobCode: 9300230,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "시간의길1",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4705882353,
  },
  {
    "Unnamed: 0": 103,
    이름: "아이언 호그",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "방방",
    mobCode: 9300060,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "돼지의공원2(히든), 남의집(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 104,
    이름: "네펜데스",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "망지/망행",
    mobCode: 4230122,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산책로, 버려진화원(히든)",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 105,
    이름: "프리져",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "망민",
    mobCode: 4230124,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "붉은산호숲",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 106,
    이름: "아이언 뮤테",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: null,
    mobCode: 4110300,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 107,
    이름: "블록골렘",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "방방",
    mobCode: 4230109,
    독: 0.0,
    불: 1.0,
    전: 2.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑5층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 108,
    이름: "제타 그레이",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "신민",
    mobCode: 9500367,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "쿨란초원3",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 109,
    이름: "이끼 달팽이",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: null,
    mobCode: 9800051,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "엘린숲",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 110,
    이름: "주니어 불독",
    레벨: 43.0,
    HP: 4200.0,
    공격력: 280.0,
    회피율: 68.0,
    경험치: 1900.0,
    메소: 23.0,
    주문서: "망지",
    mobCode: 4230108,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동굴속동굴(히든)",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.4523809524,
  },
  {
    "Unnamed: 0": 111,
    이름: "스파커",
    레벨: 43.0,
    HP: 4200.0,
    공격력: 280.0,
    회피율: 68.0,
    경험치: 1900.0,
    메소: 23.0,
    주문서: "활/신민",
    mobCode: 4230123,
    독: 0.0,
    불: 2.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "소라껍질언덕",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.4523809524,
  },
  {
    "Unnamed: 0": 112,
    이름: "흑저",
    레벨: 43.0,
    HP: 4200.0,
    공격력: 280.0,
    회피율: 68.0,
    경험치: 1900.0,
    메소: 23.0,
    주문서: null,
    mobCode: 4230502,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.4523809524,
  },
  {
    "Unnamed: 0": 113,
    이름: "월묘",
    레벨: 43.0,
    HP: 4200.0,
    공격력: 280.0,
    회피율: 68.0,
    경험치: 1900.0,
    메소: 23.0,
    주문서: "신민",
    mobCode: 9300459,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "까막산입구",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.4523809524,
  },
  {
    "Unnamed: 0": 114,
    이름: "스켈독",
    레벨: 44.0,
    HP: 4600.0,
    공격력: 280.0,
    회피율: 69.0,
    경험치: 2040.0,
    메소: 24.0,
    주문서: "신민/창",
    mobCode: 9305504,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "유적의무덤1",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4434782609,
  },
  {
    "Unnamed: 0": 115,
    이름: "마스터 로보",
    레벨: 44.0,
    HP: 4600.0,
    공격력: 280.0,
    회피율: 69.0,
    경험치: 2040.0,
    메소: 24.0,
    주문서: "투민/전민",
    mobCode: 4230112,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "장난감공장<2공정>1구역",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4434782609,
  },
  {
    "Unnamed: 0": 116,
    이름: "플라티안",
    레벨: 44.0,
    HP: 4600.0,
    공격력: 280.0,
    회피율: 69.0,
    경험치: 2040.0,
    메소: 24.0,
    주문서: "장공",
    mobCode: 9800039,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "로스웰초원2",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.4434782609,
  },
  {
    "Unnamed: 0": 117,
    이름: "카파 드레이크",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "방방",
    mobCode: 4130100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산7",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 118,
    이름: "루나픽시",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "신민",
    mobCode: 4230106,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "구름공원3, 구름공원4",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 119,
    이름: "청화사",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: null,
    mobCode: 9300160,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 120,
    이름: "홍화사",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: null,
    mobCode: 9300161,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 121,
    이름: "강화된 아이언 뮤테",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: null,
    mobCode: 9300254,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 122,
    이름: "킹 블록골렘",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "전힘/창/두손검",
    mobCode: 9500120,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "에오스탑8층",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 123,
    이름: "울트라 그레이",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "장공",
    mobCode: 9800042,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "쿨란초원5",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 124,
    이름: "엄티",
    레벨: 46.0,
    HP: 5400.0,
    공격력: 320.0,
    회피율: 71.0,
    경험치: 2310.0,
    메소: 27.0,
    주문서: "망힘/장공",
    mobCode: 4130101,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "따뜻한모래밭",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4277777778,
  },
  {
    "Unnamed: 0": 125,
    이름: "마스터 크로노스",
    레벨: 46.0,
    HP: 5400.0,
    공격력: 320.0,
    회피율: 71.0,
    경험치: 2310.0,
    메소: 27.0,
    주문서: "스태프/두손도끼",
    mobCode: 9300231,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "시간의길4",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.4277777778,
  },
  {
    "Unnamed: 0": 126,
    이름: "메카티안",
    레벨: 46.0,
    HP: 5400.0,
    공격력: 320.0,
    회피율: 71.0,
    경험치: 2310.0,
    메소: 27.0,
    주문서: "스태프",
    mobCode: 9800040,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "로스웰초원5",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.4277777778,
  },
  {
    "Unnamed: 0": 127,
    이름: "트리로드",
    레벨: 46.0,
    HP: 5400.0,
    공격력: 320.0,
    회피율: 71.0,
    경험치: 2310.0,
    메소: 27.0,
    주문서: null,
    mobCode: 9800052,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "엘린숲",
    "경험치 효율": 0.4277777778,
  },
  {
    "Unnamed: 0": 128,
    이름: "머미독",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: "망힘/두손둔기",
    mobCode: 9305516,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "유적의무덤2,3",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 129,
    이름: "다크 네펜데스",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: "망힘/두손둔기",
    mobCode: 4130104,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "산책로2",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 130,
    이름: "단지",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: null,
    mobCode: 4230505,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 131,
    이름: "미스릴 뮤테",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: null,
    mobCode: 9300255,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 132,
    이름: "롬바드",
    레벨: 47.0,
    HP: 87000.0,
    공격력: 408.0,
    회피율: 82.0,
    경험치: 36630.0,
    메소: 2240.0,
    주문서: "두손검/두손도끼/두손둔기/완드/창/표창/단검",
    mobCode: 9303006,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "숨겨진탑(히든)",
    보스: "O",
    지역: "루디브리엄",
    "경험치 효율": 0.4210344828,
  },
  {
    "Unnamed: 0": 133,
    이름: "레이스",
    레벨: 48.0,
    HP: 6200.0,
    공격력: 360.0,
    회피율: 73.0,
    경험치: 2560.0,
    메소: 29.0,
    주문서: "방방",
    mobCode: 9500156,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "1호선<4구역>, 2호선<3구역>",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4129032258,
  },
  {
    "Unnamed: 0": 134,
    이름: "클랑",
    레벨: 48.0,
    HP: 6200.0,
    공격력: 360.0,
    회피율: 73.0,
    경험치: 2560.0,
    메소: 29.0,
    주문서: "한손둔기/상힘",
    mobCode: 9800021,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "로랑앤클랑",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4129032258,
  },
  {
    "Unnamed: 0": 135,
    이름: "삼단지",
    레벨: 48.0,
    HP: 6200.0,
    공격력: 360.0,
    회피율: 73.0,
    경험치: 2560.0,
    메소: 29.0,
    주문서: null,
    mobCode: 4230506,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.4129032258,
  },
  {
    "Unnamed: 0": 136,
    이름: "원로 그레이",
    레벨: 49.0,
    HP: 6600.0,
    공격력: 370.0,
    회피율: 75.0,
    경험치: 2680.0,
    메소: 31.0,
    주문서: "스태프",
    mobCode: 9800043,
    독: 0.0,
    불: 2.0,
    전: 2.0,
    얼: 2.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": "바나드의초원(히든)",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.4060606061,
  },
  {
    "Unnamed: 0": 137,
    이름: "드레이크",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "방방",
    mobCode: 9200012,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "드레이크사냥터",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 138,
    이름: "주니어 예티",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "석궁/장공",
    mobCode: 9810402,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "빙판조심1,2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 139,
    이름: "훈련용 짚인형",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: null,
    mobCode: 5120503,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 140,
    이름: "강화된 미스릴 뮤테",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: null,
    mobCode: 9300253,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 141,
    이름: "호돌이",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "석궁/장공/방방",
    mobCode: 9800032,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "작은연못가",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 142,
    이름: "파이어봄",
    레벨: 51.0,
    HP: 7400.0,
    공격력: 400.0,
    회피율: 77.0,
    경험치: 2910.0,
    메소: 34.0,
    주문서: "망지",
    mobCode: 5100002,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시련의동굴1, 온천의원천(히든)",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3932432432,
  },
  {
    "Unnamed: 0": 143,
    이름: "훈련용 나무인형",
    레벨: 51.0,
    HP: 7400.0,
    공격력: 400.0,
    회피율: 77.0,
    경험치: 2910.0,
    메소: 34.0,
    주문서: null,
    mobCode: 9800059,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3932432432,
  },
  {
    "Unnamed: 0": 144,
    이름: "스톤버그",
    레벨: 51.0,
    HP: 7400.0,
    공격력: 400.0,
    회피율: 77.0,
    경험치: 2910.0,
    메소: 34.0,
    주문서: null,
    mobCode: 9800055,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "엘린숲",
    "경험치 효율": 0.3932432432,
  },
  {
    "Unnamed: 0": 145,
    이름: "크로코",
    레벨: 52.0,
    HP: 7800.0,
    공격력: 420.0,
    회피율: 78.0,
    경험치: 3010.0,
    메소: 36.0,
    주문서: "방방",
    mobCode: 9300235,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한크로코2 ",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3858974359,
  },
  {
    "Unnamed: 0": 146,
    이름: "러스터픽시",
    레벨: 52.0,
    HP: 7800.0,
    공격력: 420.0,
    회피율: 78.0,
    경험치: 3010.0,
    메소: 36.0,
    주문서: "망지/전민",
    mobCode: 9300236,
    독: 0.0,
    불: 2.0,
    전: 2.0,
    얼: 2.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": "구름공원5, 구름공원6",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.3858974359,
  },
  {
    "Unnamed: 0": 147,
    이름: "샐리온",
    레벨: 53.0,
    HP: 8200.0,
    공격력: 430.0,
    회피율: 79.0,
    경험치: 3120.0,
    메소: 36.0,
    주문서: "망힘/한손검",
    mobCode: 5120001,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "붉은빛의정원2",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.3804878049,
  },
  {
    "Unnamed: 0": 148,
    이름: "라이오너",
    레벨: 53.0,
    HP: 8200.0,
    공격력: 430.0,
    회피율: 79.0,
    경험치: 3120.0,
    메소: 36.0,
    주문서: "망지/두손검",
    mobCode: 5120002,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "노란빛의정원2",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.3804878049,
  },
  {
    "Unnamed: 0": 149,
    이름: "그류핀",
    레벨: 53.0,
    HP: 8200.0,
    공격력: 430.0,
    회피율: 79.0,
    경험치: 3120.0,
    메소: 36.0,
    주문서: "망행",
    mobCode: 5120003,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "푸른빛의정원2",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.3804878049,
  },
  {
    "Unnamed: 0": 150,
    이름: "도라지",
    레벨: 53.0,
    HP: 8200.0,
    공격력: 430.0,
    회피율: 79.0,
    경험치: 3120.0,
    메소: 36.0,
    주문서: null,
    mobCode: 5120501,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3804878049,
  },
  {
    "Unnamed: 0": 151,
    이름: "호걸",
    레벨: 53.0,
    HP: 8200.0,
    공격력: 430.0,
    회피율: 79.0,
    경험치: 3120.0,
    메소: 36.0,
    주문서: "망행/두손둔기",
    mobCode: 9800034,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "까막산골짜기",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.3804878049,
  },
  {
    "Unnamed: 0": 152,
    이름: "늙은 도라지",
    레벨: 54.0,
    HP: 8600.0,
    공격력: 450.0,
    회피율: 80.0,
    경험치: 3220.0,
    메소: 37.0,
    주문서: null,
    mobCode: 5120502,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3744186047,
  },
  {
    "Unnamed: 0": 153,
    이름: "로이드",
    레벨: 54.0,
    HP: 8600.0,
    공격력: 450.0,
    회피율: 80.0,
    경험치: 3220.0,
    메소: 37.0,
    주문서: null,
    mobCode: 9800046,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.3744186047,
  },
  {
    "Unnamed: 0": 154,
    이름: "머신 MT-09",
    레벨: 54.0,
    HP: 8600.0,
    공격력: 450.0,
    회피율: 80.0,
    경험치: 3220.0,
    메소: 37.0,
    주문서: "석궁",
    mobCode: 9800041,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "괴수퇴치(히든)",
    보스: null,
    지역: "지구방위본부",
    "경험치 효율": 0.3744186047,
  },
  {
    "Unnamed: 0": 155,
    이름: "스톤골렘",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: "활/석궁",
    mobCode: 9800000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "골렘의사원1(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 156,
    이름: "멜러디",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: "스태프",
    mobCode: 5300100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "사악한기운의숲2(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 157,
    이름: "헥터",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: "망민",
    mobCode: 9800012,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "차가운벌판1,2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 158,
    이름: "비급",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: null,
    mobCode: 9300164,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 159,
    이름: "이끼버섯",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: null,
    mobCode: 9800053,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "엘린숲",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 160,
    이름: "다크 주니어 예티",
    레벨: 56.0,
    HP: 9400.0,
    공격력: 500.0,
    회피율: 83.0,
    경험치: 3410.0,
    메소: 42.0,
    주문서: "완드",
    mobCode: 9800010,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "날카로운절벽1",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3627659574,
  },
  {
    "Unnamed: 0": 161,
    이름: "달곰",
    레벨: 56.0,
    HP: 9400.0,
    공격력: 500.0,
    회피율: 83.0,
    경험치: 3410.0,
    메소: 42.0,
    주문서: null,
    mobCode: 9800061,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3627659574,
  },
  {
    "Unnamed: 0": 162,
    이름: "삼미호",
    레벨: 56.0,
    HP: 9400.0,
    공격력: 500.0,
    회피율: 83.0,
    경험치: 3410.0,
    메소: 42.0,
    주문서: "스태프/완드",
    mobCode: 9800033,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "달고개(히든)",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.3627659574,
  },
  {
    "Unnamed: 0": 163,
    이름: "스켈레톤 사병",
    레벨: 57.0,
    HP: 9800.0,
    공격력: 520.0,
    회피율: 84.0,
    경험치: 3510.0,
    메소: 44.0,
    주문서: "아대/스태프/완드",
    mobCode: 5150001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "제1군영(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3581632653,
  },
  {
    "Unnamed: 0": 164,
    이름: "쿨리좀비",
    레벨: 57.0,
    HP: 9800.0,
    공격력: 520.0,
    회피율: 84.0,
    경험치: 3510.0,
    메소: 44.0,
    주문서: "방방",
    mobCode: 5130107,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "죽은나무의숲1",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3581632653,
  },
  {
    "Unnamed: 0": 165,
    이름: "마이너 좀비",
    레벨: 57.0,
    HP: 9800.0,
    공격력: 520.0,
    회피율: 84.0,
    경험치: 3510.0,
    메소: 44.0,
    주문서: "상힘",
    mobCode: 9800088,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "폐광2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3581632653,
  },
  {
    "Unnamed: 0": 166,
    이름: "원시멧돼지",
    레벨: 57.0,
    HP: 9800.0,
    공격력: 520.0,
    회피율: 84.0,
    경험치: 3510.0,
    메소: 44.0,
    주문서: null,
    mobCode: 9800054,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "엘린숲",
    "경험치 효율": 0.3581632653,
  },
  {
    "Unnamed: 0": 167,
    이름: "다크 스톤골렘",
    레벨: 58.0,
    HP: 10200.0,
    공격력: 540.0,
    회피율: 85.0,
    경험치: 3600.0,
    메소: 47.0,
    주문서: "두손도끼/두손둔기",
    mobCode: 9800001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "골렘의사원3(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3529411765,
  },
  {
    "Unnamed: 0": 168,
    이름: "화이트팽",
    레벨: 58.0,
    HP: 10200.0,
    공격력: 540.0,
    회피율: 85.0,
    경험치: 3600.0,
    메소: 47.0,
    주문서: "방방",
    mobCode: 9800013,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "차디찬벌판",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3529411765,
  },
  {
    "Unnamed: 0": 169,
    이름: "천록",
    레벨: 58.0,
    HP: 10200.0,
    공격력: 540.0,
    회피율: 85.0,
    경험치: 3600.0,
    메소: 47.0,
    주문서: null,
    mobCode: 5120505,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3529411765,
  },
  {
    "Unnamed: 0": 170,
    이름: "네오 휴로이드",
    레벨: 58.0,
    HP: 10200.0,
    공격력: 540.0,
    회피율: 85.0,
    경험치: 3600.0,
    메소: 47.0,
    주문서: null,
    mobCode: 9800047,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.3529411765,
  },
  {
    "Unnamed: 0": 171,
    이름: "믹스 골렘",
    레벨: 59.0,
    HP: 10600.0,
    공격력: 560.0,
    회피율: 86.0,
    경험치: 3680.0,
    메소: 48.0,
    주문서: "장공/방방",
    mobCode: 5150000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "골렘의사원4(히든), 무너진골렘의성터(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3471698113,
  },
  {
    "Unnamed: 0": 172,
    이름: "선인 인형",
    레벨: 59.0,
    HP: 10600.0,
    공격력: 560.0,
    회피율: 86.0,
    경험치: 3680.0,
    메소: 48.0,
    주문서: null,
    mobCode: 5090001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3471698113,
  },
  {
    "Unnamed: 0": 173,
    이름: "레드 드레이크",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "귀지/방방",
    mobCode: 9800026,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한골짜기2(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 174,
    이름: "페페",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "망민/망힘/아대/스태프",
    mobCode: 9500142,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "왕관을휘날리며(히든)",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 175,
    이름: "판다곰",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: null,
    mobCode: 9800062,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 176,
    이름: "깨비(변신전)",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "X",
    mobCode: 9300083,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "도깨비산마루",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 177,
    이름: "깨비(변신후)",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "투민/전민/상힘",
    mobCode: 9800036,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "도깨비산마루",
    보스: null,
    지역: "아랫마을",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 178,
    이름: "버피",
    레벨: 61.0,
    HP: 11700.0,
    공격력: 610.0,
    회피율: 91.0,
    경험치: 3950.0,
    메소: 54.0,
    주문서: "망지/스태프/완드",
    mobCode: 9300315,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길1",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3376068376,
  },
  {
    "Unnamed: 0": 179,
    이름: "와일드 카고",
    레벨: 62.0,
    HP: 12400.0,
    공격력: 620.0,
    회피율: 93.0,
    경험치: 4130.0,
    메소: 56.0,
    주문서: "투민/한손둔기/완드/장마",
    mobCode: 6230100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "와일드카고의영역",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3330645161,
  },
  {
    "Unnamed: 0": 180,
    이름: "원공",
    레벨: 62.0,
    HP: 12400.0,
    공격력: 620.0,
    회피율: 93.0,
    경험치: 4130.0,
    메소: 56.0,
    주문서: null,
    mobCode: 9300165,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3330645161,
  },
  {
    "Unnamed: 0": 181,
    이름: "스켈레톤 장교",
    레벨: 63.0,
    HP: 13100.0,
    공격력: 630.0,
    회피율: 93.0,
    경험치: 4300.0,
    메소: 58.0,
    주문서: "장공/스태프",
    mobCode: 6230602,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "제3군영(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3282442748,
  },
  {
    "Unnamed: 0": 182,
    이름: "주니어 루이넬",
    레벨: 63.0,
    HP: 13100.0,
    공격력: 630.0,
    회피율: 93.0,
    경험치: 4300.0,
    메소: 58.0,
    주문서: "X",
    mobCode: 6230401,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "미구현",
    보스: null,
    지역: "미구현",
    "경험치 효율": 0.3282442748,
  },
  {
    "Unnamed: 0": 183,
    이름: "소울 테니",
    레벨: 63.0,
    HP: 13100.0,
    공격력: 630.0,
    회피율: 93.0,
    경험치: 4300.0,
    메소: 58.0,
    주문서: "장공/스태프",
    mobCode: 6230400,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길1",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3282442748,
  },
  {
    "Unnamed: 0": 184,
    이름: "아이스 드레이크",
    레벨: 64.0,
    HP: 13800.0,
    공격력: 640.0,
    회피율: 95.0,
    경험치: 4470.0,
    메소: 61.0,
    주문서: "방방",
    mobCode: 9800027,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "드레이크의영역",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3239130435,
  },
  {
    "Unnamed: 0": 185,
    이름: "다크 페페",
    레벨: 64.0,
    HP: 13800.0,
    공격력: 640.0,
    회피율: 95.0,
    경험치: 4470.0,
    메소: 61.0,
    주문서: "방방",
    mobCode: 9800011,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "날카로운절벽2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3239130435,
  },
  {
    "Unnamed: 0": 186,
    이름: "설산의 마녀",
    레벨: 64.0,
    HP: 207000.0,
    공격력: 768.0,
    회피율: 105.0,
    경험치: 67130.0,
    메소: 4880.0,
    주문서: "한손도끼/완드/활/창/석궁",
    mobCode: 9800016,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "차디찬벌판",
    보스: "O",
    지역: "엘나스",
    "경험치 효율": 0.3242995169,
  },
  {
    "Unnamed: 0": 187,
    이름: "게비알",
    레벨: 64.0,
    HP: 13800.0,
    공격력: 640.0,
    회피율: 95.0,
    경험치: 4470.0,
    메소: 61.0,
    주문서: null,
    mobCode: 6130204,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3239130435,
  },
  {
    "Unnamed: 0": 188,
    이름: "예티",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: "방방",
    mobCode: 9800014,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "설인의골짜기(히든)",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 189,
    이름: "호문",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: null,
    mobCode: 9300145,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 190,
    이름: "묘선",
    레벨: 66.0,
    HP: 15200.0,
    공격력: 680.0,
    회피율: 99.0,
    경험치: 5000.0,
    메소: 68.0,
    주문서: null,
    mobCode: 9800064,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3289473684,
  },
  {
    "Unnamed: 0": 191,
    이름: "레이지버피",
    레벨: 66.0,
    HP: 15200.0,
    공격력: 680.0,
    회피율: 99.0,
    경험치: 5000.0,
    메소: 68.0,
    주문서: "귀지",
    mobCode: 6230300,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길1",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3289473684,
  },
  {
    "Unnamed: 0": 192,
    이름: "마스터 소울 테니",
    레벨: 67.0,
    HP: 15900.0,
    공격력: 700.0,
    회피율: 100.0,
    경험치: 5160.0,
    메소: 70.0,
    주문서: "망행/단검",
    mobCode: 6230500,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길1",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3245283019,
  },
  {
    "Unnamed: 0": 193,
    이름: "다크 드레이크",
    레벨: 68.0,
    HP: 16600.0,
    공격력: 720.0,
    회피율: 102.0,
    경험치: 5320.0,
    메소: 73.0,
    주문서: "망힘/망민/투민/상힘",
    mobCode: 9800028,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "드레이크의푸른동굴(히든)",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3204819277,
  },
  {
    "Unnamed: 0": 194,
    이름: "다크 예티",
    레벨: 68.0,
    HP: 16600.0,
    공격력: 720.0,
    회피율: 102.0,
    경험치: 5320.0,
    메소: 73.0,
    주문서: "망행",
    mobCode: 9800015,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "날카로운절벽1",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3204819277,
  },
  {
    "Unnamed: 0": 195,
    이름: "크루",
    레벨: 68.0,
    HP: 16600.0,
    공격력: 720.0,
    회피율: 102.0,
    경험치: 5320.0,
    메소: 73.0,
    주문서: null,
    mobCode: 9300080,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3204819277,
  },
  {
    "Unnamed: 0": 196,
    이름: "사이티",
    레벨: 68.0,
    HP: 16600.0,
    공격력: 720.0,
    회피율: 102.0,
    경험치: 5320.0,
    메소: 73.0,
    주문서: null,
    mobCode: 9300146,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.3204819277,
  },
  {
    "Unnamed: 0": 197,
    이름: "타우로마시스",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: "한손도끼/폴암/창",
    mobCode: 9800029,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "신전의입구1",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 198,
    이름: "레쉬",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: null,
    mobCode: 9810302,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 199,
    이름: "캡틴",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: null,
    mobCode: 9300242,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "무릉도원",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 200,
    이름: "클라크",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: "귀지/장공",
    mobCode: 9300319,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 201,
    이름: "불독",
    레벨: 72.0,
    HP: 23400.0,
    공격력: 770.0,
    회피율: 109.0,
    경험치: 7130.0,
    메소: 80.0,
    주문서: "망행/창",
    mobCode: 7130001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시련의동굴1,2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3047008547,
  },
  {
    "Unnamed: 0": 202,
    이름: "비틀",
    레벨: 72.0,
    HP: 23400.0,
    공격력: 770.0,
    회피율: 109.0,
    경험치: 7130.0,
    메소: 80.0,
    주문서: null,
    mobCode: 7130002,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.3047008547,
  },
  {
    "Unnamed: 0": 203,
    이름: "호브",
    레벨: 72.0,
    HP: 23400.0,
    공격력: 770.0,
    회피율: 109.0,
    경험치: 7130.0,
    메소: 80.0,
    주문서: null,
    mobCode: 9810301,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.3047008547,
  },
  {
    "Unnamed: 0": 204,
    이름: "스켈레톤 지휘관",
    레벨: 73.0,
    HP: 26100.0,
    공격력: 800.0,
    회피율: 110.0,
    경험치: 7860.0,
    메소: 83.0,
    주문서: "귀지/전행",
    mobCode: 7130103,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "유적의낭떠러지",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3011494253,
  },
  {
    "Unnamed: 0": 205,
    이름: "루이넬",
    레벨: 73.0,
    HP: 26100.0,
    공격력: 800.0,
    회피율: 110.0,
    경험치: 7860.0,
    메소: 83.0,
    주문서: "석궁",
    mobCode: 9500131,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "검은빛의정원1,2(히든), 버섯의전당(히든)",
    보스: null,
    지역: "오르비스",
    "경험치 효율": 0.3011494253,
  },
  {
    "Unnamed: 0": 206,
    이름: "호문쿨루",
    레벨: 73.0,
    HP: 26100.0,
    공격력: 800.0,
    회피율: 110.0,
    경험치: 7860.0,
    메소: 83.0,
    주문서: null,
    mobCode: 9300147,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: null,
    지역: "니할사막",
    "경험치 효율": 0.3011494253,
  },
  {
    "Unnamed: 0": 207,
    이름: "다크 레쉬",
    레벨: 74.0,
    HP: 28800.0,
    공격력: 830.0,
    회피율: 111.0,
    경험치: 8570.0,
    메소: 85.0,
    주문서: null,
    mobCode: 7130501,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2975694444,
  },
  {
    "Unnamed: 0": 208,
    이름: "버푼",
    레벨: 74.0,
    HP: 28800.0,
    공격력: 830.0,
    회피율: 111.0,
    경험치: 8570.0,
    메소: 85.0,
    주문서: "망행",
    mobCode: 9300320,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2975694444,
  },
  {
    "Unnamed: 0": 209,
    이름: "타우로스피어",
    레벨: 75.0,
    HP: 31500.0,
    공격력: 850.0,
    회피율: 112.0,
    경험치: 9630.0,
    메소: 90.0,
    주문서: "두손도끼",
    mobCode: 9800030,
    독: 0.0,
    불: 2.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "신전의입구4",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.3057142857,
  },
  {
    "Unnamed: 0": 210,
    이름: "웨어울프",
    레벨: 75.0,
    HP: 31500.0,
    공격력: 850.0,
    회피율: 112.0,
    경험치: 9630.0,
    메소: 90.0,
    주문서: "아대/귀지/완드",
    mobCode: 9810403,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "날카로운절벽3,4",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.3057142857,
  },
  {
    "Unnamed: 0": 211,
    이름: "D.로이",
    레벨: 75.0,
    HP: 31500.0,
    공격력: 850.0,
    회피율: 112.0,
    경험치: 9630.0,
    메소: 90.0,
    주문서: null,
    mobCode: 9800049,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "니할사막",
    "경험치 효율": 0.3057142857,
  },
  {
    "Unnamed: 0": 212,
    이름: "듀얼 비틀",
    레벨: 76.0,
    HP: 34200.0,
    공격력: 880.0,
    회피율: 112.0,
    경험치: 10330.0,
    메소: 93.0,
    주문서: null,
    mobCode: 7130003,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.3020467836,
  },
  {
    "Unnamed: 0": 213,
    이름: "핀 호브",
    레벨: 76.0,
    HP: 34200.0,
    공격력: 880.0,
    회피율: 112.0,
    경험치: 10330.0,
    메소: 93.0,
    주문서: null,
    mobCode: 7130601,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.3020467836,
  },
  {
    "Unnamed: 0": 214,
    이름: "다크 클라크",
    레벨: 76.0,
    HP: 34200.0,
    공격력: 880.0,
    회피율: 112.0,
    경험치: 10330.0,
    메소: 93.0,
    주문서: "석궁/전지",
    mobCode: 9800067,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.3020467836,
  },
  {
    "Unnamed: 0": 215,
    이름: "딥 버푼",
    레벨: 77.0,
    HP: 36900.0,
    공격력: 900.0,
    회피율: 113.0,
    경험치: 11020.0,
    메소: 95.0,
    주문서: "망힘/두손검",
    mobCode: 9300321,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길2",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2986449864,
  },
  {
    "Unnamed: 0": 216,
    이름: "예티와 페페",
    레벨: 78.0,
    HP: 39600.0,
    공격력: 920.0,
    회피율: 114.0,
    경험치: 11700.0,
    메소: 99.0,
    주문서: "X",
    mobCode: 9500133,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "얼음골짜기2",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.2954545455,
  },
  {
    "Unnamed: 0": 217,
    이름: "헹키",
    레벨: 78.0,
    HP: 39600.0,
    공격력: 920.0,
    회피율: 114.0,
    경험치: 11700.0,
    메소: 99.0,
    주문서: null,
    mobCode: 9500161,
    독: 1.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2954545455,
  },
  {
    "Unnamed: 0": 218,
    이름: "라이칸스로프",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: "방방",
    mobCode: 9800086,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "늑대의영역1,2,3",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 219,
    이름: "하프",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: null,
    mobCode: 9810300,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 220,
    이름: "호문스큘러",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: null,
    mobCode: 8110300,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "니할사막",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 221,
    이름: "시간의 눈",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: null,
    mobCode: 8200000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 222,
    이름: "다크 예티와 페페",
    레벨: 82.0,
    HP: 56000.0,
    공격력: 1000.0,
    회피율: 117.0,
    경험치: 15820.0,
    메소: 109.0,
    주문서: "X",
    mobCode: 8140100,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "위험한절벽",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.2825,
  },
  {
    "Unnamed: 0": 223,
    이름: "블러드 하프",
    레벨: 83.0,
    HP: 61500.0,
    공격력: 1050.0,
    회피율: 118.0,
    경험치: 17190.0,
    메소: 113.0,
    주문서: null,
    mobCode: 9500163,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2795121951,
  },
  {
    "Unnamed: 0": 224,
    이름: "파이렛",
    레벨: 83.0,
    HP: 61500.0,
    공격력: 1050.0,
    회피율: 118.0,
    경험치: 17190.0,
    메소: 113.0,
    주문서: "망민/전민",
    mobCode: 9300322,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길3",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2795121951,
  },
  {
    "Unnamed: 0": 225,
    이름: "망둥이",
    레벨: 85.0,
    HP: 72500.0,
    공격력: 1100.0,
    회피율: 120.0,
    경험치: 18540.0,
    메소: 116.0,
    주문서: "아대/신민",
    mobCode: 7130020,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "깊은바다협곡2",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.2557241379,
  },
  {
    "Unnamed: 0": 226,
    이름: "버크",
    레벨: 85.0,
    HP: 72500.0,
    공격력: 1100.0,
    회피율: 120.0,
    경험치: 20600.0,
    메소: 116.0,
    주문서: null,
    mobCode: 9810001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.284137931,
  },
  {
    "Unnamed: 0": 227,
    이름: "데스테니",
    레벨: 85.0,
    HP: 72500.0,
    공격력: 1100.0,
    회피율: 120.0,
    경험치: 20600.0,
    메소: 116.0,
    주문서: "하행/망지",
    mobCode: 9800068,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길3",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.284137931,
  },
  {
    "Unnamed: 0": 228,
    이름: "듀얼 파이렛",
    레벨: 87.0,
    HP: 83500.0,
    공격력: 1150.0,
    회피율: 121.0,
    경험치: 23240.0,
    메소: 120.0,
    주문서: "폴암",
    mobCode: 7160000,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "삐뚤어진시간(히든)",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2783233533,
  },
  {
    "Unnamed: 0": 229,
    이름: "듀얼 버크",
    레벨: 88.0,
    HP: 89000.0,
    공격력: 1170.0,
    회피율: 122.0,
    경험치: 24520.0,
    메소: 123.0,
    주문서: null,
    mobCode: 8140111,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 2.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.275505618,
  },
  {
    "Unnamed: 0": 230,
    이름: "붉은 켄타우로스",
    레벨: 88.0,
    HP: 89000.0,
    공격력: 1170.0,
    회피율: 122.0,
    경험치: 24520.0,
    메소: 123.0,
    주문서: null,
    mobCode: 9500165,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.275505618,
  },
  {
    "Unnamed: 0": 231,
    이름: "푸른 켄타우로스",
    레벨: 88.0,
    HP: 89000.0,
    공격력: 1170.0,
    회피율: 122.0,
    경험치: 24520.0,
    메소: 123.0,
    주문서: null,
    mobCode: 9500166,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.275505618,
  },
  {
    "Unnamed: 0": 232,
    이름: "검은 켄타우로스",
    레벨: 88.0,
    HP: 89000.0,
    공격력: 1170.0,
    회피율: 122.0,
    경험치: 24520.0,
    메소: 123.0,
    주문서: null,
    mobCode: 9500164,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.275505618,
  },
  {
    "Unnamed: 0": 233,
    이름: "마스터 데스테니",
    레벨: 89.0,
    HP: 94500.0,
    공격력: 1180.0,
    회피율: 123.0,
    경험치: 25770.0,
    메소: 127.0,
    주문서: "망지/창",
    mobCode: 7130300,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "사라진시간(히든)",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2726984127,
  },
  {
    "Unnamed: 0": 234,
    이름: "파이어독",
    레벨: 90.0,
    HP: 100000.0,
    공격력: 1200.0,
    회피율: 124.0,
    경험치: 27000.0,
    메소: 131.0,
    주문서: "망행/두손검",
    mobCode: 8140500,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시련의동굴3, 완완지옥탕",
    보스: null,
    지역: "엘나스",
    "경험치 효율": 0.27,
  },
  {
    "Unnamed: 0": 235,
    이름: "폭렬 망둥이집",
    레벨: 90.0,
    HP: 100000.0,
    공격력: 1200.0,
    회피율: 124.0,
    경험치: 27000.0,
    메소: 131.0,
    주문서: "X",
    mobCode: 8140555,
    독: 2.0,
    불: 2.0,
    전: 2.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "깊은바다협곡2",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.27,
  },
  {
    "Unnamed: 0": 236,
    이름: "블루 드래곤 터틀",
    레벨: 90.0,
    HP: 100000.0,
    공격력: 1200.0,
    회피율: 124.0,
    경험치: 27000.0,
    메소: 131.0,
    주문서: null,
    mobCode: 9500378,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.27,
  },
  {
    "Unnamed: 0": 237,
    이름: "추억의 사제",
    레벨: 91.0,
    HP: 107000.0,
    공격력: 1250.0,
    회피율: 124.0,
    경험치: 28600.0,
    메소: 135.0,
    주문서: null,
    mobCode: 8200001,
    독: 2.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2672897196,
  },
  {
    "Unnamed: 0": 238,
    이름: "본 피쉬",
    레벨: 92.0,
    HP: 114000.0,
    공격력: 1270.0,
    회피율: 125.0,
    경험치: 30170.0,
    메소: 139.0,
    주문서: "전민/전지",
    mobCode: 9300443,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "깊은바다협곡1",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.2646491228,
  },
  {
    "Unnamed: 0": 239,
    이름: "레드 드래곤 터틀",
    레벨: 92.0,
    HP: 114000.0,
    공격력: 1270.0,
    회피율: 125.0,
    경험치: 30170.0,
    메소: 139.0,
    주문서: null,
    mobCode: 9500379,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2646491228,
  },
  {
    "Unnamed: 0": 240,
    이름: "바이킹",
    레벨: 93.0,
    HP: 121000.0,
    공격력: 1300.0,
    회피율: 126.0,
    경험치: 31710.0,
    메소: 143.0,
    주문서: "석궁",
    mobCode: 9300324,
    독: 0.0,
    불: 2.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린시간의길4",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2620661157,
  },
  {
    "Unnamed: 0": 241,
    이름: "스퀴드",
    레벨: 94.0,
    HP: 128000.0,
    공격력: 1320.0,
    회피율: 127.0,
    경험치: 33230.0,
    메소: 147.0,
    주문서: "하행/신민",
    mobCode: 9300444,
    독: 2.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한바다협곡1",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.259609375,
  },
  {
    "Unnamed: 0": 242,
    이름: "추억의 신관",
    레벨: 94.0,
    HP: 128000.0,
    공격력: 1320.0,
    회피율: 127.0,
    경험치: 33230.0,
    메소: 147.0,
    주문서: null,
    mobCode: 8200002,
    독: 2.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.259609375,
  },
  {
    "Unnamed: 0": 243,
    이름: "리스튼",
    레벨: 95.0,
    HP: 135000.0,
    공격력: 1350.0,
    회피율: 128.0,
    경험치: 36000.0,
    메소: 152.0,
    주문서: null,
    mobCode: 8140702,
    독: 1.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2666666667,
  },
  {
    "Unnamed: 0": 244,
    이름: "팬텀워치",
    레벨: 95.0,
    HP: 135000.0,
    공격력: 1350.0,
    회피율: 128.0,
    경험치: 36000.0,
    메소: 152.0,
    주문서: "표창/석궁",
    mobCode: 9800069,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진시간의길4",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2666666667,
  },
  {
    "Unnamed: 0": 245,
    이름: "리셀 스퀴드",
    레벨: 97.0,
    HP: 149000.0,
    공격력: 1400.0,
    회피율: 129.0,
    경험치: 38990.0,
    메소: 156.0,
    주문서: "장공/장마",
    mobCode: 8142100,
    독: 0.0,
    불: 2.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한바다협곡2",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.2616778523,
  },
  {
    "Unnamed: 0": 246,
    이름: "레드 와이번",
    레벨: 97.0,
    HP: 149000.0,
    공격력: 1400.0,
    회피율: 129.0,
    경험치: 38990.0,
    메소: 156.0,
    주문서: null,
    mobCode: 9800102,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2616778523,
  },
  {
    "Unnamed: 0": 247,
    이름: "브레스튼",
    레벨: 97.0,
    HP: 149000.0,
    공격력: 1400.0,
    회피율: 129.0,
    경험치: 38990.0,
    메소: 156.0,
    주문서: null,
    mobCode: 8140703,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2616778523,
  },
  {
    "Unnamed: 0": 248,
    이름: "추억의 수호병",
    레벨: 98.0,
    HP: 156000.0,
    공격력: 1420.0,
    회피율: 130.0,
    경험치: 40440.0,
    메소: 160.0,
    주문서: null,
    mobCode: 8200003,
    독: 2.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2592307692,
  },
  {
    "Unnamed: 0": 249,
    이름: "기간틱 바이킹",
    레벨: 98.0,
    HP: 156000.0,
    공격력: 1420.0,
    회피율: 130.0,
    경험치: 40440.0,
    메소: 160.0,
    주문서: "아대/방행",
    mobCode: 9500136,
    독: 0.0,
    불: 2.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "꼬여버린시간(히든)",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2592307692,
  },
  {
    "Unnamed: 0": 250,
    이름: "G.팬텀워치",
    레벨: 99.0,
    HP: 163000.0,
    공격력: 1450.0,
    회피율: 131.0,
    경험치: 41870.0,
    메소: 165.0,
    주문서: "아대/상힘",
    mobCode: 9800070,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "금지된시간(히든)",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2568711656,
  },
  {
    "Unnamed: 0": 251,
    이름: "샤크",
    레벨: 100.0,
    HP: 170000.0,
    공격력: 1500.0,
    회피율: 132.0,
    경험치: 43270.0,
    메소: 169.0,
    주문서: "방방/망힘/망민",
    mobCode: 9300462,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "난파선의무덤",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.2545294118,
  },
  {
    "Unnamed: 0": 252,
    이름: "그린 코니언",
    레벨: 100.0,
    HP: 170000.0,
    공격력: 1500.0,
    회피율: 132.0,
    경험치: 43270.0,
    메소: 169.0,
    주문서: null,
    mobCode: 9500374,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2545294118,
  },
  {
    "Unnamed: 0": 253,
    이름: "블루 와이번",
    레벨: 101.0,
    HP: 173000.0,
    공격력: 1520.0,
    회피율: 132.0,
    경험치: 43630.0,
    메소: 173.0,
    주문서: null,
    mobCode: 9800103,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2521965318,
  },
  {
    "Unnamed: 0": 254,
    이름: "추억의 수호대장",
    레벨: 101.0,
    HP: 173000.0,
    공격력: 1520.0,
    회피율: 132.0,
    경험치: 43630.0,
    메소: 173.0,
    주문서: null,
    mobCode: 8200004,
    독: 2.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2521965318,
  },
  {
    "Unnamed: 0": 255,
    이름: "콜드 샤크",
    레벨: 102.0,
    HP: 176000.0,
    공격력: 1540.0,
    회피율: 133.0,
    경험치: 44000.0,
    메소: 179.0,
    주문서: "망행",
    mobCode: 9300463,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "난파선의무덤",
    보스: null,
    지역: "아쿠아리움",
    "경험치 효율": 0.25,
  },
  {
    "Unnamed: 0": 256,
    이름: "다크 와이번",
    레벨: 103.0,
    HP: 179000.0,
    공격력: 1560.0,
    회피율: 134.0,
    경험치: 44350.0,
    메소: 184.0,
    주문서: null,
    mobCode: 9800104,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2477653631,
  },
  {
    "Unnamed: 0": 257,
    이름: "다크 코니언",
    레벨: 105.0,
    HP: 185000.0,
    공격력: 1620.0,
    회피율: 136.0,
    경험치: 45040.0,
    메소: 190.0,
    주문서: null,
    mobCode: 9800101,
    독: 2.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2434594595,
  },
  {
    "Unnamed: 0": 258,
    이름: "뉴트 주니어",
    레벨: 105.0,
    HP: 185000.0,
    공격력: 1620.0,
    회피율: 136.0,
    경험치: 45040.0,
    메소: 190.0,
    주문서: null,
    mobCode: 8190000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2434594595,
  },
  {
    "Unnamed: 0": 259,
    이름: "후회의 사제",
    레벨: 106.0,
    HP: 188000.0,
    공격력: 1650.0,
    회피율: 136.0,
    경험치: 45370.0,
    메소: 195.0,
    주문서: null,
    mobCode: 9800106,
    독: 0.0,
    불: 1.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2413297872,
  },
  {
    "Unnamed: 0": 260,
    이름: "게이트키퍼",
    레벨: 108.0,
    HP: 194000.0,
    공격력: 1700.0,
    회피율: 138.0,
    경험치: 46030.0,
    메소: 200.0,
    주문서: "하행/신민",
    mobCode: 9800071,
    독: 0.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "뒤틀린회랑",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2372680412,
  },
  {
    "Unnamed: 0": 261,
    이름: "타나토스",
    레벨: 108.0,
    HP: 194000.0,
    공격력: 1700.0,
    회피율: 138.0,
    경험치: 46030.0,
    메소: 200.0,
    주문서: "장공/신민",
    mobCode: 9800072,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 2.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "잊혀진회랑",
    보스: null,
    지역: "루디브리엄",
    "경험치 효율": 0.2372680412,
  },
  {
    "Unnamed: 0": 262,
    이름: "후회의 신관",
    레벨: 109.0,
    HP: 197000.0,
    공격력: 1730.0,
    회피율: 139.0,
    경험치: 46350.0,
    메소: 206.0,
    주문서: null,
    mobCode: 8200006,
    독: 0.0,
    불: 1.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2352791878,
  },
  {
    "Unnamed: 0": 263,
    이름: "네스트 골렘",
    레벨: 110.0,
    HP: 200000.0,
    공격력: 1760.0,
    회피율: 140.0,
    경험치: 46660.0,
    메소: 212.0,
    주문서: null,
    mobCode: 8190002,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2333,
  },
  {
    "Unnamed: 0": 264,
    이름: "스켈레곤",
    레벨: 110.0,
    HP: 200000.0,
    공격력: 1760.0,
    회피율: 140.0,
    경험치: 46660.0,
    메소: 212.0,
    주문서: null,
    mobCode: 9500380,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.2333,
  },
  {
    "Unnamed: 0": 265,
    이름: "스켈로스",
    레벨: 113.0,
    HP: 215000.0,
    공격력: 1860.0,
    회피율: 142.0,
    경험치: 48940.0,
    메소: 217.0,
    주문서: null,
    mobCode: 9500381,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": null,
    보스: null,
    지역: "리프레",
    "경험치 효율": 0.227627907,
  },
  {
    "Unnamed: 0": 266,
    이름: "후회의 수호병",
    레벨: 113.0,
    HP: 215000.0,
    공격력: 1860.0,
    회피율: 142.0,
    경험치: 48940.0,
    메소: 217.0,
    주문서: null,
    mobCode: 9800107,
    독: 0.0,
    불: 1.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.227627907,
  },
  {
    "Unnamed: 0": 267,
    이름: "후회의 수호대장",
    레벨: 116.0,
    HP: 230000.0,
    공격력: 1960.0,
    회피율: 144.0,
    경험치: 51110.0,
    메소: 226.0,
    주문서: null,
    mobCode: 8200008,
    독: 0.0,
    불: 1.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": 0.2222173913,
  },
  {
    "Unnamed: 0": 268,
    이름: "망각의 사제",
    레벨: 121.0,
    HP: null,
    공격력: 2100.0,
    회피율: 148.0,
    경험치: null,
    메소: 232.0,
    주문서: null,
    mobCode: 8200009,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 269,
    이름: "망각의 신관",
    레벨: 124.0,
    HP: null,
    공격력: 2180.0,
    회피율: 151.0,
    경험치: null,
    메소: 239.0,
    주문서: null,
    mobCode: 9800110,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 270,
    이름: "망각의 수호병",
    레벨: 128.0,
    HP: null,
    공격력: 2330.0,
    회피율: 154.0,
    경험치: null,
    메소: 246.0,
    주문서: null,
    mobCode: 9800111,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 271,
    이름: "망각의 수호대장",
    레벨: 131.0,
    HP: null,
    공격력: 2450.0,
    회피율: 156.0,
    경험치: null,
    메소: 253.0,
    주문서: null,
    mobCode: 9800112,
    독: 1.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 272,
    이름: "아이언보어",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "두손검",
    mobCode: 4230400,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "아이언보어의땅",
    보스: null,
    지역: "빅토리아",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 273,
    이름: "마노",
    레벨: 20.0,
    HP: 3750.0,
    공격력: 84.0,
    회피율: 42.0,
    경험치: 3000.0,
    메소: 400.0,
    주문서: "스태프/완드/두손검/전민",
    mobCode: 9500337,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "해안가풀숲3",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 274,
    이름: "스텀피",
    레벨: 35.0,
    HP: 24750.0,
    공격력: 192.0,
    회피율: 67.0,
    경험치: 13200.0,
    메소: 1120.0,
    주문서: "장공/한손검/한손도끼/한손둔기",
    mobCode: 9500338,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동쪽바위산5",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 275,
    이름: "데우",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9500339,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "니할사막",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 276,
    이름: "세르프",
    레벨: 47.0,
    HP: 87000.0,
    공격력: 408.0,
    회피율: 82.0,
    경험치: 36630.0,
    메소: 2240.0,
    주문서: "장공/장마/표창/한손검",
    mobCode: 9800025,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "바다해초탑",
    보스: "O",
    지역: "아쿠아리움",
    "경험치 효율": 0.4210344828,
  },
  {
    "Unnamed: 0": 277,
    이름: "파우스트",
    레벨: 55.0,
    HP: 135000.0,
    공격력: 564.0,
    회피율: 93.0,
    경험치: 49840.0,
    메소: 3280.0,
    주문서: "장마/망지/스태프/아대",
    mobCode: 9500341,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "사악한기운의숲1(히든)",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.3691851852,
  },
  {
    "Unnamed: 0": 278,
    이름: "킹크랑",
    레벨: 55.0,
    HP: 135000.0,
    공격력: 564.0,
    회피율: 93.0,
    경험치: 49840.0,
    메소: 3280.0,
    주문서: "망힘/망행/석궁/장공",
    mobCode: 9800023,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "따뜻한모래밭",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.3691851852,
  },
  {
    "Unnamed: 0": 279,
    이름: "타이머",
    레벨: 59.0,
    HP: 159000.0,
    공격력: 672.0,
    회피율: 96.0,
    경험치: 55300.0,
    메소: 3840.0,
    주문서: "신민/장마/장공/표창/아대/완드/창/두손검",
    mobCode: 9500344,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "잃어버린시간2",
    보스: "O",
    지역: "루디브리엄",
    "경험치 효율": 0.3477987421,
  },
  {
    "Unnamed: 0": 280,
    이름: "다일",
    레벨: 65.0,
    HP: 217500.0,
    공격력: 792.0,
    회피율: 107.0,
    경험치: 69600.0,
    메소: 5200.0,
    주문서: "투민/전행/단검/폴암/활",
    mobCode: 9500346,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한크로코1",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.32,
  },
  {
    "Unnamed: 0": 281,
    이름: "제노",
    레벨: 65.0,
    HP: 217500.0,
    공격력: 792.0,
    회피율: 107.0,
    경험치: 69600.0,
    메소: 5200.0,
    주문서: "신민/장마/장공/표창/아대/완드/창/두손검",
    mobCode: 9800044,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "도곤스기지입구(히든)",
    보스: "O",
    지역: "지구방위본부",
    "경험치 효율": 0.32,
  },
  {
    "Unnamed: 0": 282,
    이름: "구미호",
    레벨: 70.0,
    HP: 270000.0,
    공격력: 888.0,
    회피율: 118.0,
    경험치: 81000.0,
    메소: 6240.0,
    주문서: "두손둔기/방행/한손검/두손도끼/아대/활/장마",
    mobCode: 9800038,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "달고개(히든)",
    보스: "O",
    지역: "아랫마을",
    "경험치 효율": 0.3,
  },
  {
    "Unnamed: 0": 283,
    이름: "태륜",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9800063,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "무릉도원",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 284,
    이름: "요괴선사",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9800065,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "무릉도원",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 285,
    이름: "엘리쟈",
    레벨: 83.0,
    HP: 922500.0,
    공격력: 1260.0,
    회피율: 128.0,
    경험치: 238060.0,
    메소: 9040.0,
    주문서: "한손검/단검/스태프/아대/폴암/활/석궁/단검",
    mobCode: 9500355,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "검은빛의정원2(히든)",
    보스: "O",
    지역: "오르비스",
    "경험치 효율": 0.2580596206,
  },
  {
    "Unnamed: 0": 286,
    이름: "스노우맨",
    레벨: 90.0,
    HP: 1500000.0,
    공격력: 1440.0,
    회피율: 134.0,
    경험치: 360000.0,
    메소: 10480.0,
    주문서: "완드/망지/스태프/장공/전민/하행",
    mobCode: 9500357,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "설인의골짜기(히든)",
    보스: "O",
    지역: "엘나스",
    "경험치 효율": 0.24,
  },
  {
    "Unnamed: 0": 287,
    이름: "대왕지네",
    레벨: 60.0,
    HP: 165000.0,
    공격력: 720.0,
    회피율: 100.0,
    경험치: 56570.0,
    메소: 4160.0,
    주문서: "전행/신민/장마/망지/장공/방방/방행/투지",
    mobCode: 9800066,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "위험한언덕(히든)",
    보스: "O",
    지역: "중국",
    "경험치 효율": 0.3428484848,
  },
  {
    "Unnamed: 0": 288,
    이름: "포장마차",
    레벨: 65.0,
    HP: 217500.0,
    공격력: 792.0,
    회피율: 107.0,
    경험치: 69600.0,
    메소: 5200.0,
    주문서: "폴암/스태프/두손검/아대/단검",
    mobCode: 9410015,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "조용한야시장뒷골목(히든)",
    보스: "O",
    지역: "대만",
    "경험치 효율": 0.32,
  },
  {
    "Unnamed: 0": 289,
    이름: "머쉬맘",
    레벨: 60.0,
    HP: 165000.0,
    공격력: 720.0,
    회피율: 100.0,
    경험치: 56570.0,
    메소: 4160.0,
    주문서: "귀지/하행/신민/장공",
    mobCode: 9500345,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "남의집(히든)",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.3428484848,
  },
  {
    "Unnamed: 0": 290,
    이름: "좀비 머쉬맘",
    레벨: 65.0,
    HP: 217500.0,
    공격력: 792.0,
    회피율: 107.0,
    경험치: 69600.0,
    메소: 5200.0,
    주문서: "한손도끼/완드/활/창/석궁",
    mobCode: 9300426,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "머쉬맘의무덤(히든)",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.32,
  },
  {
    "Unnamed: 0": 291,
    이름: "블루 머쉬맘",
    레벨: 90.0,
    HP: 1500000.0,
    공격력: 1440.0,
    회피율: 134.0,
    경험치: 360000.0,
    메소: 10480.0,
    주문서: "귀지/하행/신민/장공",
    mobCode: 9500176,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "버섯의전당(히든)",
    보스: "O",
    지역: "일본",
    "경험치 효율": 0.24,
  },
  {
    "Unnamed: 0": 292,
    이름: "주니어 발록",
    레벨: 80.0,
    HP: 675000.0,
    공격력: 1140.0,
    회피율: 126.0,
    경험치: 180000.0,
    메소: 8480.0,
    주문서: "한손검/단검/스태프/아대/폴암/활/석궁/단검",
    mobCode: 9800031,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "저주받은신전",
    보스: "O",
    지역: "빅토리아",
    "경험치 효율": 0.2666666667,
  },
  {
    "Unnamed: 0": 293,
    이름: "크림슨 발록",
    레벨: 100.0,
    HP: 2550000.0,
    공격력: 1800.0,
    회피율: 142.0,
    경험치: 556360.0,
    메소: 13520.0,
    주문서: "혼돈/백",
    mobCode: 9500171,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "엘리니아<->오르비스행 배",
    보스: "O",
    지역: "배",
    "경험치 효율": 0.2181803922,
  },
  {
    "Unnamed: 0": 294,
    이름: "그리프",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300512,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "리프레",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 295,
    이름: "마뇽",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300511,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "리프레",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 296,
    이름: "레비아탄",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300516,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "리프레",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 297,
    이름: "도도",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300517,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 298,
    이름: "릴리노흐",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300518,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 299,
    이름: "라이카",
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 9300519,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: "O",
    지역: "시간의신전",
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 300,
    이름: "코-크 달팽이",
    레벨: 10.0,
    HP: 100.0,
    공격력: 25.0,
    회피율: 0.0,
    경험치: 132.0,
    메소: 3.0,
    주문서: "X",
    mobCode: 9500144,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<옐로우>",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 1.32,
  },
  {
    "Unnamed: 0": 301,
    이름: "코-크 슬라임",
    레벨: 15.0,
    HP: 160.0,
    공격력: 40.0,
    회피율: 24.0,
    경험치: 165.0,
    메소: 4.0,
    주문서: "X",
    mobCode: 9500151,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<작은언덕1>(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 1.03125,
  },
  {
    "Unnamed: 0": 302,
    이름: "코-크 버섯",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 220.0,
    메소: 5.0,
    주문서: "X",
    mobCode: 9500152,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<작은언덕1>(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.88,
  },
  {
    "Unnamed: 0": 303,
    이름: "코-크텀프",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 407.0,
    메소: 7.0,
    주문서: "X",
    mobCode: 9500153,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<작은언덕2>(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.74,
  },
  {
    "Unnamed: 0": 304,
    이름: "코-크텀프 라이트",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 726.0,
    메소: 9.0,
    주문서: "X",
    mobCode: 9500154,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<골드>",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.66,
  },
  {
    "Unnamed: 0": 305,
    이름: "코-크 돼지",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 968.0,
    메소: 14.0,
    주문서: "X",
    mobCode: 9500143,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 스키캠프<작은언덕3>(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.5866666667,
  },
  {
    "Unnamed: 0": 306,
    이름: "코-크 씰",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1353.0,
    메소: 17.0,
    주문서: "X",
    mobCode: 9500145,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "작은 사잇길(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.55,
  },
  {
    "Unnamed: 0": 307,
    이름: "플레이 씰",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1925.0,
    메소: 22.0,
    주문서: "X",
    mobCode: 9500145,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "작은 사잇길(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.5065789474,
  },
  {
    "Unnamed: 0": 308,
    이름: "이글루 터틀",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2398.0,
    메소: 26.0,
    주문서: "X",
    mobCode: 9500148,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 밸리2",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.4796,
  },
  {
    "Unnamed: 0": 309,
    이름: "코-크 골렘",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 3080.0,
    메소: 33.0,
    주문서: "X",
    mobCode: 9500149,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 밸리3",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.44,
  },
  {
    "Unnamed: 0": 310,
    이름: "아이스 골렘",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 4147.0,
    메소: 52.0,
    주문서: "X",
    mobCode: 9500150,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 골렘의숲(히든)",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.377,
  },
  {
    "Unnamed: 0": 311,
    이름: "예티와 코-크텀프",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 6182.0,
    메소: 78.0,
    주문서: "X",
    mobCode: 9500147,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "코-크 밸리4",
    보스: null,
    지역: "코크타운",
    "경험치 효율": 0.3434444444,
  },
  {
    "Unnamed: 0": 312,
    이름: "들개",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "석궁",
    mobCode: 9410000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서문정거리1",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 313,
    이름: "멋쟁이 들개",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: "폴암",
    mobCode: 9410001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서문정거리2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 314,
    이름: "험악한 들개",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: "창",
    mobCode: 9410002,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서문정뒷골목",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 315,
    이름: "광대 원숭이",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "방방",
    mobCode: 9410003,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서문정거리5",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 316,
    이름: "폭주족 원숭이",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: "방행",
    mobCode: 9410004,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서문정거리5",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 317,
    이름: "레드 버블티",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "투민/전민",
    mobCode: 9410005,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장거리3",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 318,
    이름: "옐로우 버블티",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "아대/표창",
    mobCode: 9410006,
    독: 1.0,
    불: 0.0,
    전: 2.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장거리4",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 319,
    이름: "그린 버블티",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "완드/활",
    mobCode: 9410007,
    독: 2.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장사잇길2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 320,
    이름: "예티 인형자판기",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "장공",
    mobCode: 9410008,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장거리2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 321,
    이름: "예티 인형",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "X",
    mobCode: 9410009,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장거리2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 322,
    이름: "주니어페페 인형자판기",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "활/석궁",
    mobCode: 9410010,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장사잇길2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 323,
    이름: "주니어페페 인형",
    레벨: 36.0,
    HP: 1920.0,
    공격력: 170.0,
    회피율: 58.0,
    경험치: 1000.0,
    메소: 15.0,
    주문서: "X",
    mobCode: 9410011,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장사잇길2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.5208333333,
  },
  {
    "Unnamed: 0": 324,
    이름: "인형자판기",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "X",
    mobCode: 9410012,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장사잇길2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 325,
    이름: "인형뽑기 기계",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "X",
    mobCode: 9410013,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "야시장사잇길2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 326,
    이름: "악마 슬라임",
    레벨: 23.0,
    HP: 400.0,
    공격력: 90.0,
    회피율: 36.0,
    경험치: 290.0,
    메소: 6.0,
    주문서: "한손검",
    mobCode: 9300027,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "첩운객실내부1,2",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.725,
  },
  {
    "Unnamed: 0": 327,
    이름: "냉염 퍼퓸(변신전)",
    레벨: 67.0,
    HP: 15900.0,
    공격력: 700.0,
    회피율: 100.0,
    경험치: 5160.0,
    메소: 70.0,
    주문서: "X",
    mobCode: 9410022,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도동부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3245283019,
  },
  {
    "Unnamed: 0": 328,
    이름: "냉염 퍼퓸(변신후)",
    레벨: 67.0,
    HP: 15900.0,
    공격력: 700.0,
    회피율: 100.0,
    경험치: 5160.0,
    메소: 70.0,
    주문서: "장마",
    mobCode: 9410023,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도동부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3245283019,
  },
  {
    "Unnamed: 0": 329,
    이름: "백화 퍼퓸(변신전)",
    레벨: 69.0,
    HP: 17300.0,
    공격력: 730.0,
    회피율: 105.0,
    경험치: 5470.0,
    메소: 75.0,
    주문서: "X",
    mobCode: 9410024,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도남부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3161849711,
  },
  {
    "Unnamed: 0": 330,
    이름: "백화 퍼퓸(변신후)",
    레벨: 69.0,
    HP: 17300.0,
    공격력: 730.0,
    회피율: 105.0,
    경험치: 5470.0,
    메소: 75.0,
    주문서: "신민",
    mobCode: 9410025,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도남부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3161849711,
  },
  {
    "Unnamed: 0": 331,
    이름: "스위트하트 퍼퓸(변신전)",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: "X",
    mobCode: 9410026,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도북부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 332,
    이름: "스위트하트 퍼퓸(변신후)",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: "상힘/하행",
    mobCode: 9410027,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "시상대도북부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 333,
    이름: "토끼귀신 인형",
    레벨: 75.0,
    HP: 31500.0,
    공격력: 850.0,
    회피율: 112.0,
    경험치: 9630.0,
    메소: 90.0,
    주문서: "투지/투민",
    mobCode: 9410032,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "도회광장동부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3057142857,
  },
  {
    "Unnamed: 0": 334,
    이름: "인형 로즈",
    레벨: 77.0,
    HP: 36900.0,
    공격력: 900.0,
    회피율: 113.0,
    경험치: 11020.0,
    메소: 95.0,
    주문서: "귀행/귀지",
    mobCode: 9410033,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "도회광장북부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.2986449864,
  },
  {
    "Unnamed: 0": 335,
    이름: "인형 잭",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: "귀민",
    mobCode: 9410034,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "도회광장남부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 336,
    이름: "매직 앰프",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: "전힘",
    mobCode: 9410028,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "명인대도동부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 337,
    이름: "매직 스피커",
    레벨: 74.0,
    HP: 28800.0,
    공격력: 830.0,
    회피율: 111.0,
    경험치: 8570.0,
    메소: 85.0,
    주문서: "전지",
    mobCode: 9410029,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "명인대도북부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.2975694444,
  },
  {
    "Unnamed: 0": 338,
    이름: "CD 모음집",
    레벨: 76.0,
    HP: 34200.0,
    공격력: 880.0,
    회피율: 112.0,
    경험치: 10330.0,
    메소: 93.0,
    주문서: "X",
    mobCode: 9410031,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "명인대도남부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3020467836,
  },
  {
    "Unnamed: 0": 339,
    이름: "시비꾼 CD",
    레벨: 72.0,
    HP: 23400.0,
    공격력: 770.0,
    회피율: 109.0,
    경험치: 7130.0,
    메소: 80.0,
    주문서: "전민",
    mobCode: 9410030,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "명인대도남부구역",
    보스: null,
    지역: "대만",
    "경험치 효율": 0.3047008547,
  },
  {
    "Unnamed: 0": 340,
    이름: "닭",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 200.0,
    메소: 5.0,
    주문서: "방방",
    mobCode: 9600001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "상해교외",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 341,
    이름: "오리",
    레벨: 22.0,
    HP: 350.0,
    공격력: 85.0,
    회피율: 35.0,
    경험치: 260.0,
    메소: 6.0,
    주문서: "두손도끼",
    mobCode: 9600002,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "상해교외",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.7428571429,
  },
  {
    "Unnamed: 0": 342,
    이름: "양",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: "창",
    mobCode: 9600003,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "상해교외",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 343,
    이름: "염소",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "스태프/완드",
    mobCode: 9600004,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "중원산악지대1",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 344,
    이름: "소",
    레벨: 33.0,
    HP: 1430.0,
    공격력: 140.0,
    회피율: 52.0,
    경험치: 790.0,
    메소: 12.0,
    주문서: "투민",
    mobCode: 9600006,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서주평원1",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.5524475524,
  },
  {
    "Unnamed: 0": 345,
    이름: "흑염소",
    레벨: 35.0,
    HP: 1650.0,
    공격력: 160.0,
    회피율: 57.0,
    경험치: 880.0,
    메소: 14.0,
    주문서: "단검",
    mobCode: 9600005,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "중원산악지대2",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.5333333333,
  },
  {
    "Unnamed: 0": 346,
    이름: "검은 양",
    레벨: 37.0,
    HP: 2190.0,
    공격력: 180.0,
    회피율: 60.0,
    경험치: 1110.0,
    메소: 16.0,
    주문서: "방방/방행",
    mobCode: 9600008,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "검은양의영토(히든)",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.5068493151,
  },
  {
    "Unnamed: 0": 347,
    이름: "쟁기소",
    레벨: 38.0,
    HP: 2460.0,
    공격력: 190.0,
    회피율: 61.0,
    경험치: 1230.0,
    메소: 17.0,
    주문서: "폴암/석궁",
    mobCode: 9600007,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "서주평원3",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.5,
  },
  {
    "Unnamed: 0": 348,
    이름: "흑곰 검사",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "창/활",
    mobCode: 9600012,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산기슭",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 349,
    이름: "백호 검사",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "망힘/망민",
    mobCode: 9600013,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산기슭",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 350,
    이름: "매 격투가",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "망지/망행",
    mobCode: 9410453,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산길1",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 351,
    이름: "남자 산적",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "표창/아대",
    mobCode: 9410467,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산길2",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 352,
    이름: "여자 산적",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: "단검/한손둔기",
    mobCode: 9410480,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "산길2",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 353,
    이름: "요괴 향로",
    레벨: 70.0,
    HP: 18000.0,
    공격력: 740.0,
    회피율: 108.0,
    경험치: 5620.0,
    메소: 78.0,
    주문서: "한손도끼/스태프",
    mobCode: 9600017,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "소림사광장",
    보스: null,
    지역: "중국",
    "경험치 효율": 0.3122222222,
  },
  {
    "Unnamed: 0": 354,
    이름: "까마귀",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: "신민/투민",
    mobCode: 9400000,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "까마귀의숲1",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 355,
    이름: "구름여우",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "두손검/한손도끼",
    mobCode: 9400002,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동물의숲",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 356,
    이름: "불너구리",
    레벨: 30.0,
    HP: 1100.0,
    공격력: 120.0,
    회피율: 48.0,
    경험치: 660.0,
    메소: 9.0,
    주문서: "방방/방행",
    mobCode: 9400001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "동물의숲",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.6,
  },
  {
    "Unnamed: 0": 357,
    이름: "제등귀신",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: "상힘/두손도끼/폴암",
    mobCode: 9400011,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 1.0,
    "추천 사냥터": "월하죽림1",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 358,
    이름: "물도깨비",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "전행/신민/활",
    mobCode: 9400012,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "월하죽림3",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 359,
    이름: "큰 구름여우",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "단검/전힘",
    mobCode: 9400004,
    독: 0.0,
    불: 0.0,
    전: 1.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "적막한묘지",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 360,
    이름: "망령",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: "망지/망민",
    mobCode: 9400003,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 1.0,
    성: 1.0,
    힐: 1.0,
    "추천 사냥터": "유령이떠도는묘지",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 361,
    이름: "수하 A",
    레벨: 45.0,
    HP: 5000.0,
    공격력: 300.0,
    회피율: 71.0,
    경험치: 2180.0,
    메소: 26.0,
    주문서: "장마",
    mobCode: 9400064,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "쇼와마을뒷골목1",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.436,
  },
  {
    "Unnamed: 0": 362,
    이름: "수하 B",
    레벨: 47.0,
    HP: 5800.0,
    공격력: 340.0,
    회피율: 72.0,
    경험치: 2440.0,
    메소: 28.0,
    주문서: "한손도끼/두손도끼",
    mobCode: 9400065,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "쇼와마을뒷골목2",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.4206896552,
  },
  {
    "Unnamed: 0": 363,
    이름: "수하 C",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: "투민/투지",
    mobCode: 9400102,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "쇼와마을뒷골목2",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 364,
    이름: "수하 D",
    레벨: 72.0,
    HP: 23400.0,
    공격력: 770.0,
    회피율: 109.0,
    경험치: 7130.0,
    메소: 80.0,
    주문서: "전행/전지",
    mobCode: 9400103,
    독: 1.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "주차장",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3047008547,
  },
  {
    "Unnamed: 0": 365,
    이름: "간부 A",
    레벨: 62.0,
    HP: 12400.0,
    공격력: 620.0,
    회피율: 93.0,
    경험치: 4130.0,
    메소: 56.0,
    주문서: "신민/장공",
    mobCode: 9400066,
    독: 1.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "불너구리대부회사",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3330645161,
  },
  {
    "Unnamed: 0": 366,
    이름: "간부 B",
    레벨: 64.0,
    HP: 13800.0,
    공격력: 640.0,
    회피율: 95.0,
    경험치: 4470.0,
    메소: 61.0,
    주문서: "상힘/하행",
    mobCode: 9400067,
    독: 1.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": "응접실",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3239130435,
  },
  {
    "Unnamed: 0": 367,
    이름: "레드 슬라임",
    레벨: 55.0,
    HP: 9000.0,
    공격력: 470.0,
    회피율: 83.0,
    경험치: 3320.0,
    메소: 41.0,
    주문서: "장마/장공/표창",
    mobCode: 7120103,
    독: 0.0,
    불: 2.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": "온천의원천(히든)",
    보스: null,
    지역: "일본",
    "경험치 효율": 0.3688888889,
  },
  {
    "Unnamed: 0": 368,
    이름: "개구리",
    레벨: 8.0,
    HP: 75.0,
    공격력: 23.0,
    회피율: 0.0,
    경험치: 100.0,
    메소: 2.0,
    주문서: null,
    mobCode: 9420001,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 1.333333333,
  },
  {
    "Unnamed: 0": 369,
    이름: "흰 닭",
    레벨: 15.0,
    HP: 160.0,
    공격력: 40.0,
    회피율: 24.0,
    경험치: 150.0,
    메소: 4.0,
    주문서: null,
    mobCode: 9420005,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.9375,
  },
  {
    "Unnamed: 0": 370,
    이름: "노란 도마뱀",
    레벨: 25.0,
    HP: 550.0,
    공격력: 100.0,
    회피율: 40.0,
    경험치: 370.0,
    메소: 7.0,
    주문서: null,
    mobCode: 9420004,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.6727272727,
  },
  {
    "Unnamed: 0": 371,
    이름: "두꺼비",
    레벨: 28.0,
    HP: 800.0,
    공격력: 110.0,
    회피율: 44.0,
    경험치: 500.0,
    메소: 8.0,
    주문서: null,
    mobCode: 9420000,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.625,
  },
  {
    "Unnamed: 0": 372,
    이름: "빨간 도마뱀",
    레벨: 40.0,
    HP: 3000.0,
    공격력: 220.0,
    회피율: 66.0,
    경험치: 1440.0,
    메소: 20.0,
    주문서: null,
    mobCode: 9420003,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.48,
  },
  {
    "Unnamed: 0": 373,
    이름: "구렁이",
    레벨: 60.0,
    HP: 11000.0,
    공격력: 600.0,
    회피율: 90.0,
    경험치: 3770.0,
    메소: 52.0,
    주문서: null,
    mobCode: 9420002,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.3427272727,
  },
  {
    "Unnamed: 0": 374,
    이름: "사나운 원숭이",
    레벨: 42.0,
    HP: 3800.0,
    공격력: 260.0,
    회피율: 67.0,
    경험치: 1750.0,
    메소: 22.0,
    주문서: null,
    mobCode: 9420007,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.4605263158,
  },
  {
    "Unnamed: 0": 375,
    이름: "어미 원숭이",
    레벨: 50.0,
    HP: 7000.0,
    공격력: 380.0,
    회피율: 77.0,
    경험치: 2800.0,
    메소: 33.0,
    주문서: null,
    mobCode: 9420008,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.4,
  },
  {
    "Unnamed: 0": 376,
    이름: "아기 원숭이",
    레벨: 20.0,
    HP: 250.0,
    공격력: 70.0,
    회피율: 32.0,
    경험치: 200.0,
    메소: 5.0,
    주문서: null,
    mobCode: 9420009,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.8,
  },
  {
    "Unnamed: 0": 377,
    이름: "두목 원숭이",
    레벨: 65.0,
    HP: 14500.0,
    공격력: 660.0,
    회피율: 97.0,
    경험치: 4830.0,
    메소: 65.0,
    주문서: null,
    mobCode: 9420010,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 2.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.3331034483,
  },
  {
    "Unnamed: 0": 378,
    이름: "붉은 도깨비",
    레벨: 85.0,
    HP: 72500.0,
    공격력: 1100.0,
    회피율: 120.0,
    경험치: 20600.0,
    메소: 116.0,
    주문서: null,
    mobCode: 9420011,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 1.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.284137931,
  },
  {
    "Unnamed: 0": 379,
    이름: "푸른 도깨비",
    레벨: 80.0,
    HP: 45000.0,
    공격력: 950.0,
    회피율: 116.0,
    경험치: 13000.0,
    메소: 106.0,
    주문서: null,
    mobCode: 9420012,
    독: 0.0,
    불: 1.0,
    전: 0.0,
    얼: 0.0,
    성: 0.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.2888888889,
  },
  {
    "Unnamed: 0": 380,
    이름: "힘센 돌도깨비",
    레벨: 90.0,
    HP: 100000.0,
    공격력: 1200.0,
    회피율: 124.0,
    경험치: 27000.0,
    메소: 131.0,
    주문서: null,
    mobCode: 9420013,
    독: 0.0,
    불: 0.0,
    전: 0.0,
    얼: 0.0,
    성: 1.0,
    힐: 0.0,
    "추천 사냥터": null,
    보스: null,
    지역: "태국",
    "경험치 효율": 0.27,
  },
  {
    "Unnamed: 0": 381,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 382,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 383,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 384,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 385,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 386,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 387,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 388,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 389,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 390,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 391,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 392,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 393,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 394,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 395,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 396,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 397,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 398,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 399,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 400,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 401,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 402,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 403,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 404,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 405,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 406,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 407,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 408,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 409,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 410,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 411,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 412,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 413,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 414,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 415,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 416,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 417,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 418,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 419,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 420,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 421,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 422,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 423,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 424,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 425,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 426,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 427,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 428,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 429,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 430,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 431,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 432,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 433,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 434,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 435,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 436,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 437,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 438,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 439,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 440,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 441,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 442,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 443,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 444,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 445,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 446,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 447,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 448,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 449,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 450,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 451,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 452,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 453,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 454,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 455,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 456,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 457,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 458,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 459,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 460,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 461,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 462,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 463,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 464,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 465,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 466,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 467,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 468,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 469,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 470,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 471,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 472,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 473,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 474,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 475,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 476,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 477,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 478,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 479,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 480,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 481,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 482,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 483,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 484,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 485,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 486,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 487,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 488,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 489,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 490,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 491,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 492,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 493,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 494,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 495,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 496,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 497,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 498,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 499,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
  {
    "Unnamed: 0": 500,
    이름: null,
    레벨: null,
    HP: null,
    공격력: null,
    회피율: null,
    경험치: null,
    메소: null,
    주문서: null,
    mobCode: 0,
    독: null,
    불: null,
    전: null,
    얼: null,
    성: null,
    힐: null,
    "추천 사냥터": null,
    보스: null,
    지역: null,
    "경험치 효율": "#DIV/0!",
  },
];
export default mobInfo;
